<template>
  <section class="sec-nous" id="sec-nous">
    <h2>{{ $t("nuestra-historia") }}</h2>
    <br />
    <div class="div1">
      <p class="textito">{{ $t("10fotos") }}</p>
    </div>
    <div class="divswip">
      <Swiperr />
    </div>
  </section>
</template>

<script>
import Swiperr from "@/components/Swiperr.vue";

export default {
  components: {
    Swiperr,
  },
};
</script>

<style scoped>
.textito {
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sec-nous {
  align-items: center;
}

.divswip {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
</style>
