<template>
  <section class="sec-boda" id="sec-boda">
    <h2>{{ $t("el-dia") }}</h2>
    <div class="content-nous">
      <div class="elhotel">
        <p>
          {{ $t("ceremonia-frase") }}
        </p>
        <img src="@/assets/hotel_convento_2.jpg" class="img-convento" />

        <div>
          <ul class="timeline">
            <!-- Item 1 -->
            <li>
              <div class="direction-r">
                <div class="flag-wrapper">
                  <img
                    class="imglogos"
                    src="../assets/logos/wedding-rings.svg"
                  />
                  <span class="flag">{{ $t("ceremonia-laica") }}</span>
                  <span class="time-wrapper"
                    ><span class="time">12h30</span></span
                  >
                </div>
                <!-- <div class="desc">..</div> -->
              </div>
            </li>

            <!-- Item 2 -->
            <li>
              <div class="direction-r">
                <div class="flag-wrapper">
                  <img
                    class="imglogos"
                    src="../assets/logos/champagne-svgrepo-com.svg"
                  />
                  <span class="flag">{{ $t("cocktail") }}</span>
                  <span class="time-wrapper"
                    ><span class="time">14h00</span></span
                  >
                </div>
                <!-- <div class="desc">..</div> -->
              </div>
            </li>

            <!-- Item 3 -->
            <li>
              <div class="direction-r">
                <div class="flag-wrapper">
                  <img
                    class="imglogos"
                    src="../assets/logos/fast-food-svgrepo-com.svg"
                  />
                  <span class="flag">{{ $t("comida") }}</span>
                  <span class="time-wrapper"
                    ><span class="time">16h00</span></span
                  >
                </div>
                <!-- <div class="desc">..</div> -->
              </div>
            </li>

            <!-- Item 4 -->
            <li>
              <div class="direction-r">
                <div class="flag-wrapper">
                  <img
                    class="imglogos"
                    src="../assets/logos/wedding-cake-svgrepo-com.svg"
                  />
                  <span class="flag">{{ $t("cafe") }}</span>
                  <span class="time-wrapper"
                    ><span class="time">18h00</span></span
                  >
                </div>
                <!-- <div class="desc">..</div> -->
              </div>
            </li>

            <!-- Item 5 -->
            <li>
              <div class="direction-r">
                <div class="flag-wrapper">
                  <img
                    class="imglogos"
                    src="../assets/logos/party-svgrepo-com.svg"
                    alt=""
                  />
                  <span class="flag">{{ $t("barra-libre") }}</span>
                  <span class="time-wrapper"
                    ><span class="time">18h30</span></span
                  >
                </div>
                <!-- <div class="desc">..</div> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
/* .sec-boda {
  background-color: beige;
} */

.imglogos {
  width: 50px;
}

.img-convento {
  width: 100%;
}

@media (min-width: 900px) {
  .img-convento {
    width: 50%;
  }
}

.content-nous {
  padding: 30px;
}

@media (max-width: 990px) {
  .content-nous {
    flex-wrap: wrap;
  }
}
</style>
