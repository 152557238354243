export default {
  "compra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compra"])},
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola i18n en espagnooll"])},
  "nosotros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])},
  "la-boda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boda"])},
  "logistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistica"])},
  "contacto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier duda o sugerencia, no dudes en contactarnos! "])},
  "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boda Marine & Antonio J."])},
  "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias"])},
  "dias-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias"])},
  "horas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas"])},
  "minutos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutos"])},
  "segundos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundos"])},
  "nuestra-historia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUESTRA HISTORIA"])},
  "ceremonia-laica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremonia Laica"])},
  "cocktail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocktail"])},
  "comida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comida"])},
  "cafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Café"])},
  "barra-libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bailar!"])},
  "la-ceremonia-y-celebracion-de-boda-tendran-lugar-en-el-hotel-convento-de-la-magdalena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ceremonia y celebración de boda tendrán lugar en el Hotel Convento de la Magdalena."])},
  "ceremonia-frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ceremonia y celebración de boda tendrán lugar en el Hotel Convento de la Magdalena el 28 de Mayo de 2022."])},
  "ceremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremonia"])},
  "ceremnoia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremnoia"])},
  "ceremnoia-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremnoia"])},
  "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento"])},
  "informacion-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si necesitas alojamiento, estas son algunas opcciones que hemos seleccionado. No dudes en contactarnos y nosotros nos encargamos de gestionar la reserva."])},
  "convento-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El hotel de la boda! El precio es de 135€/noche/habitación doble, desayuno y spa incluidos. Si esta opción te interesa para la noche de la boda (o alguna noche más) no dudes en avisarnos antes del 15 de abril. Nosotros nos encargamos de reservar!"])},
  "convento-precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio: 135€ / Habitacion doble con desayuno incluido"])},
  "isaldelgenil-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa rural en Cuevas Bajas (el pueblo de Antonio). Esta casa está reservada entre el 27 de mayo y el 29 de mayo y puede albergar hasta 20 personas. El precio es de 20€ por persona y noche. Existe la posibilidad de reservar el desayuno y comidas típicas de la zona."])},
  "isladelgenil-precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio: 135€ / Habitacion doble con desayuno incluido"])},
  "quedan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quedan..."])},
  "10-fotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 años en 10 fotos"])},
  "el-dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El dia de la boda"])},
  "hotel-plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo mejor: disfrutaremos del desayuno juntos al dia siguiente!"])},
  "casitas-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra opción de alojamiento rural en Cuevas Bajas. Dos casas con una piscina y jardín comunes que pueden albergar hasta 5 personas en cada casa (10 personas en total)."])},
  "transporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporte"])},
  "transporte-descripccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El día de la boda, pondremos a disposición un autobús para los desplazamientos de ida y vuelta entre los pueblos de Cuevas Bajas, Benameji y Antequera."])},
  "contactotitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
  "10fotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 años en 10 fotos! "])},
  "boda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boda"])}
}