<template>
  <div style="height: 50vh; width: 90vw">
    <l-map
      v-model="zoom"
      v-model:zoom="zoom"
      :center="[37.00498983516322, -4.602857889317921]"
      :zoom="zoom"
      @move="log('move')"
    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>

      <l-marker
        :lat-lng="[36.91429281971301, -4.676650769588473]"
        @moveend="log('moveend')"
      >
        <l-tooltip> El hotel de la boda </l-tooltip>
      </l-marker>

      <l-marker :lat-lng="[37.235861660737065, -4.487344255643756]">
        <l-tooltip> El pueblo de Antonio </l-tooltip>
      </l-marker>

      <l-marker
        :lat-lng="[36.69399675454776, -4.489894462241981]"
        @moveend="log('moveend')"
      >
        <l-tooltip> Aeropuerto de Malaga </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      zoom: 5,
      iconWidth: 10,
      iconHeight: 10,
    };
  },
  computed: {
    iconUrl() {
      return "../assets/logos/party-svgrepo-com.svg";
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
  },
  methods: {
    log(a) {
      console.log(a);
    },
    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
  },
};
</script>
