<template>
  <section class="sec-logis" id="sec-logis">
    <h2>{{ $t("alojamiento") }}</h2>
    <br />
    <p>
      {{ $t("informacion-alojamiento") }}
    </p>
    <br />

    <div class="card-group mb-4">
      <div class="card">
        <img
          src="../assets/hotel_convento.jpg"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body">
          <h4 class="card-title">Hotel Convento la Magdalena</h4>
          <br />
          <p class="card-text">
            {{ $t("convento-alojamiento") }}
          </p>
          <a
            href="https://www.hotellamagdalena.com/"
            target="_blank"
            class="btn"
            >+ Info</a
          >
        </div>
      </div>

      <div class="card">
        <img src="../assets/hotel-isla2.jpg" class="card-img-top" alt="..." />
        <div class="card-body">
          <h5 class="card-title">Casa rural Isla del Genil</h5>
          <p class="card-text">
            {{ $t("isaldelgenil-alojamiento") }}
          </p>
          <a
            href="https://www.escapadarural.com/casa-rural/malaga/isla-del-genil"
            class="btn"
            target="_blank"
            >+ Info</a
          >
        </div>
      </div>

      <div class="card">
        <img
          src="../assets/hotel_lascanteras.jpg"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body">
          <h5 class="card-title">Casas las Canteras</h5>
          <p class="card-text">
            {{ $t("casitas-alojamiento") }}
          </p>
          <a href="http://www.casaslascanteras.com/" target="_blank" class="btn"
            >+ Info</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.card-deck {
  margin: 20px;
}

.card {
  border-color: #adb5bd;
  margin-bottom: 20px !important;
}
.btn {
  background-color: #adb5bd;
}

.sec-logis {
  padding: 20px;
}

.container {
  display: flex;
}

.container__column {
  flex: 1;
  /* Space between columns */
  margin: 0 8px;

  /* Layout each column */
  display: flex;
  flex-direction: column;
}

.container__content {
  /* Take available height */
  flex: 1;
}
</style>
