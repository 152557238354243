<template>
  <section class="sec-1 inicio">
    <div class="titlewrapper">
      <h1 class="typing-demo">{{ $t("titulo") }}</h1>
      <h2 id="fecha">28/05/2022</h2>
    </div>

    <div>
      <h2>{{ $t("quedan") }}</h2>
      <Countdown
        deadline="2022-5-28 12:00:00"
        secondFlipColor="#ffffff"
        labelColor="#ffffff"
        :labels="{
          days: this.$t('dias-0'),
          hours: this.$t('horas'),
          minutes: this.$t('minutos'),
          seconds: this.$t('segundos'),
        }"
      />
    </div>
    <div>
      <a href="#sec-nous">
        <div class="scroll-down"></div>
      </a>
    </div>
  </section>
</template>

<script>
import { Countdown } from "vue3-flip-countdown";

export default {
  components: {
    Countdown,
  },
};
</script>

<style scoped>
section {
  min-height: 100vh;
  width: 100%;
}
.sec-1 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("~@/assets/img-paris-burlesque.jpg");
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: space-around;
}

.titlewrapper {
  /*This part is important for centering*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-around;
}

.typing-demo {
  width: 22ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  padding: 20px;
  /* font-family: monospace; */
  /* font-size: 2em; */
}

#fecha {
  color: white;
}

.inicio h2 {
  color: white;
}

.scroll-down {
  width: 30px;
  height: 50px;
  position: absolute;
  border: 2px solid white;
  border-radius: 50px;
  bottom: 10px;
  left: 50%;
  cursor: pointer;
}

.scroll-down::before,
.scroll-down::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  height: 10px;
  width: 10px;
  transform: translate(-50%, -100%) rotate(45deg);
  border: 2px solid white;
  border-top: transparent;
  border-left: transparent;
  animation: scroll-down 1s ease-in-out infinite;
}

.scroll-down::after {
  top: 30%;
  animation-delay: 0.3s;
}

@keyframes scroll-down {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 90%;
  }
}
</style>
