export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "compra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
  "nosotros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous"])},
  "la-boda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jour J"])},
  "logistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistique"])},
  "contacto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une question, une remarque, une suggestion ? Contactez-nous !"])},
  "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mariage Marine & Antonio"])},
  "dias-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
  "horas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures"])},
  "minutos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "segundos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondes"])},
  "nuestra-historia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTRE HISTOIRE"])},
  "ceremonia-laica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cérémonie laïque"])},
  "cocktail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocktail"])},
  "comida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repas"])},
  "cafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Café"])},
  "barra-libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiesta !"])},
  "ceremonia-frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cérémonie et célébration auront lieu à l'Hotel Convento la Magdalena le samedi 28 mai 2022."])},
  "quedan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il reste"])},
  "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
  "informacion-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici quelques informations pratiques qui, on espère, vous aideront à préparer votre venue. N’hésitez pas a nous contacter si une des ces options vous intéressent et nous ferons la réservation pour vous !"])},
  "10-fotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dix ans en dix photos"])},
  "el-dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jour J"])},
  "convento-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s’agit du lieu du mariage. L’hôtel propose des chambres à 135 euros la chambre double avec petit déjeuner inclus (et accès spa & piscine). Si cette option vous intéresse pour la nuit du vendredi, du samedi ou les deux n’hésitez pas à revenir vers nous avant le 15 avril au plus tard. Nous nous chargeons d’envoyer la rooming list (encore une déformation professionnelle de Marine :) ) à l’hôtel. "])},
  "isaldelgenil-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez découvrir là où Antonio a grandi c’est par ici ! Cette maison est réservée du vendredi 27 mai au dimanche 29 mai. Cette maison peut accueillir jusqu’à 20 personnes. Son prix : 20 euros par nuit et par personne, avec la possibilité de commander un petit déjeuner type d’un petit déjeuner espagnol pour 3€."])},
  "casitas-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une autre possibilité d’hébergement au village d’Antonio. Deux maisons avec une capacité d’hébergement de 5 personnes chaque. Piscine et jardin inclus !"])},
  "transporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "transporte-descripccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A défaut de pouvoir mettre un avion affrété au départ de Paris pour nos invités, nous allons mettre en place un système de navette le jour du mariage pour vous permettre de profiter pleinement de la journée. "])},
  "contactotitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "10fotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 ans en 10 photos !"])}
}