<template>
  <section class="sectioncont" id="#sec-contact">
    <h2>{{ $t("contactotitle") }}</h2>
    <br />
    <br />
    <div>👪</div>
    <ol class="contact">
      <p>{{ $t("contacto") }}</p>
      <br />
      <li>
        Antonio: +33 623527353
        <a href="https://wa.me/33623527353"
          ><img
            src="https://seeklogo.com/images/W/whatsapp-icon-logo-BDC0A8063B-seeklogo.com.png"
            alt=""
            style="width: 20px"
        /></a>
      </li>
      <br />
      <li>
        Marine: +33 646534731
        <a href="https://wa.me/33646534731"
          ><img
            src="https://seeklogo.com/images/W/whatsapp-icon-logo-BDC0A8063B-seeklogo.com.png"
            alt=""
            style="width: 20px"
        /></a>
      </li>
    </ol>
  </section>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  min-height: 10vh;
}
.sectioncont {
  align-content: center;
}
.contact {
  font-size: 20px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/* .hello {
  margin: 100px auto;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 20px 20px;
  border-radius: 8px;
  max-width: 600px;
} */
</style>
