<template>
  <app-header></app-header>
  <section-portada />
  <section-nous />
  <section-boda />
  <section-transporte />
  <section-alojamiento />
  <section-contact />

  <particles-bg
    type="custom"
    :config="config"
    :bg="false"
    class="canvasheart"
  />
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "@/components/Header.vue";
import SectionTitle from "./components/SectionTitle.vue";
import SectionNous from "./components/SectionNous.vue";
import SectionBoda from "./components/SectionBoda.vue";
import SectionTransporte from "./components/SectionTransporte.vue";
import SectionAlojamiento from "./components/SectionAlojamiento.vue";

import Contact from "./components/Contact.vue";
import { ParticlesBg } from "particles-bg-vue";
import icon from "./assets/icon.js";

export default {
  name: "App",
  components: {
    "app-header": Header,
    "section-portada": SectionTitle,
    "section-nous": SectionNous,
    "section-boda": SectionBoda,
    "section-transporte": SectionTransporte,
    "section-alojamiento": SectionAlojamiento,
    "section-contact": Contact,
    ParticlesBg,
  },
  data() {
    return {
      config: {
        num: [4, 7],
        rps: 0.1,
        radius: [5, 40],
        life: [1.5, 3],
        v: [2, 3],
        tha: [-30, 30],
        body: icon,
        alpha: [0.6, 0],
        scale: [0.1, 0.4],
        position: "all",
        cross: "dead",
        random: 15,
      },
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Parisienne&family=Playfair+Display:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"); */

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

* {
  margin: 0;
  padding: 0;
}

.canvasheart {
  width: 100%;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
} /*16px*/

body {
  font-family: "Playfair Display";
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

body,
html {
  height: 100%;
}

h1 {
  color: #ffffff;
  font-weight: bolder;
}

h2 {
  text-align: center;
  font-weight: 900;
  padding-top: 20px;
}

h3 fecha {
  color: aqua;
}

/* h3 {
  color: #ffffff;
} */

section {
  min-height: 100vh;
  width: 100%;
}

/* Testing divs */
.div1 {
  /* width: 100px; */
  /* height: 100px; */
  /* background-color: #673d3d; */
  /* flex-grow: 1.5; */
}

.div2 {
  width: 500px;
  /* height: 100px; */
  /* background-color: #67553d; */
}

.sec-nous {
  display: flex;
  flex-direction: column;
}

.text-historia {
  text-align: center;
  padding-top: 15px;
  font-style: italic;
}

.container-pics {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-init {
  /* background-color: rgb(245, 245, 219, 0.5);
  border-radius: 50% 20% / 10% 80%;
  padding: 20px;
  margin: 20px; */
  /* margin-top: 150px; */
}

.alojamientos {
  display: flex;
  flex-wrap: wrap;
}

.alojamiento:hover {
  background-color: aqua;
}

.alojamiento {
  border: 5px solid black;
}

/* Styling hyperlinks: LVHA link, visited, hover, active in this ORDER */
a:link {
  color: #67553d;
  text-decoration: none;
}

a:visited {
  color: #67553d9c;
}

a:hover {
  color: red;
  font-weight: bold;
}

a:active {
  color: white;
  background-color: black;
}

.main-header {
  align-items: stretch;
  grid-column: auto;
}

/* ================ The Timeline ================ */

.timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline li {
  padding: 1em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
  text-align: left;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(248, 248, 248);
  padding: 6px 10px;
  margin-left: 10px;
  border-radius: 5px;

  font-weight: 600;
  text-align: left;
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15),
    0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

/* .direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 20;
  width: 20;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: rgb(248, 248, 248);
  border-width: 8px;
  pointer-events: none;
} */

.time-wrapper {
  display: inline;
  line-height: 1em;
  font-size: 0.8em;
  color: rgb(250, 80, 80);
  vertical-align: middle;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248, 248, 248);
}

.desc {
  margin: 1em 0.75em 0 0;
  font-size: 0.77777em;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}

/* ================ Timeline Media Queries ================ */

@media screen and (min-width: 900px ?? max-width: 660px) {
  .direction-l .desc,
  .direction-r .desc {
    margin: 1em 4em 0 4em;
  }

  .timeline {
    width: 80%;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
</style>
