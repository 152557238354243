export default {
  "compra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buy"])},
  "nosotros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "la-boda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Wedding day"])},
  "logistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics"])},
  "contacto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marine & Antonio's wedding"])},
  "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "dias-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
  "horas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "minutos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "segundos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconds"])},
  "nuestra-historia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR STORY"])},
  "ceremonia-laica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremony"])},
  "cocktail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocktail"])},
  "comida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
  "cafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cofee"])},
  "barra-libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let't dance!"])},
  "ceremonia-frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ceremony and wedding celebration will take place at the Hotel Convento la Magdalena on the 28th of May 2022."])},
  "ceremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aaaah"])},
  "ceremnoia-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qqqqqq"])},
  "quedan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left:"])},
  "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
  "informacion-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme suggestions for accomodation places around the wedding place and Antonio's village. If you're insterested, get it touch with us and we will take care of booking for you!"])},
  "10-fotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten years in ten pictures"])},
  "el-dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The wedding's day"])},
  "convento-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hotel where the wedding will take place. The price is 135€ per night for a double room (breakfast, and spa & pool included). Do not hesitate to contact us if you want to book a place here for the wedding day (or even before / after) and we will make the reservation for you. "])},
  "isaldelgenil-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beautiful country house at Antonio's village! We have booked it from 27th to 29th may and there's room for 20 people. The price is 20€ per person per night. It is possible to order Andalusian traditional breakfast (3€) and other lunch options!"])},
  "casitas-alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another country side accomodation option in Antonio's village. There are two houses available sharing a swimming pool and garden. There's room for 10 people (5 people in each house)."])},
  "transporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "transporte-descripccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will hire a bus that will serve the villages of Cuevas Bajas, Benameji and Antequera. No need to take your car if you're staying at one of these places! "])},
  "contactotitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "10fotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 years in 10 pictures!"])}
}