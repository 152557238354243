<template>
  <swiper class="mySwiper" :navigation="true" :modules="modules">
    <swiper-slide>
      <img src="../assets/nous/2012.png" class="imgswip" />
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2013.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2014.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2015.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/IMG_20200125_144142.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2017.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2018.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2019.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2020.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/nous/2021.jpg" class="imgswip" />
      <h4 class="text-historia"></h4>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
// import "swiper/modules/effect-fade/effect-fade.min.css";

// import "./style.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import required modules
import { Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>

<style>
/* .swiper {
  display: flex;
  align-content: flex-end;
} */

.mySwiper {
  height: 50vh;
  width: 100vw;
  /* width: 100%; */
}

@media (min-width: 900px) {
  .mySwiper {
    height: 90vh;
    width: 30vw;
    /* width: 100%; */
  }
}

/* .SwiperSlide {
  max-width: 50%;
} */

.imgswip {
  max-width: 100%;
}
</style>
