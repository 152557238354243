<template>
  <div>
    <h2>{{ $t("transporte") }}</h2>
    <br />
    <p>
      {{ $t("transporte-descripccion") }}
    </p>
  </div>

  <div class="transport-elements">
    <div class="transport-items">
      <ul class="timeline">
        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Benameji</span>
              <span class="time-wrapper"
                ><span class="time">10:45am</span></span
              >
            </div>
            <div class="desc">Specific location to be defined</div>
          </div>
        </li>

        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Cuevas Bajas</span>
              <span class="time-wrapper"
                ><span class="time">11h15 am</span></span
              >
            </div>
            <div class="desc">Specific location to be defined</div>
          </div>
        </li>

        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Antequera</span>
              <span class="time-wrapper"><span class="time">12:00</span></span>
            </div>
            <div class="desc">Specific location to be defined</div>
          </div>
        </li>
      </ul>
    </div>

    <LeafletMapa />

    <!-- <div class="container">
      <div class="row">
        <div class="col-md-10">
          <ul class="cbp_tmtimeline">
            <li>
              <time class="cbp_tmtime" datetime="2017-11-04T03:45"
                ><span>10h45</span> <span>Salida del autobus</span></time
              >
              <div class="cbp_tmicon bg-blush">
                <i class="zmdi zmdi-pin"></i>
              </div>
              <div class="cbp_tmlabel">
                <h2>Benameji</h2>
              </div>
            </li>

            <li>
              <time class="cbp_tmtime" datetime="2017-11-04T03:45"
                ><span>11h15</span></time
              >
              <div class="cbp_tmicon bg-blush">
                <i class="zmdi zmdi-pin"></i>
              </div>
              <div class="cbp_tmlabel">
                <h2>Cuevas Bajas</h2>
              </div>
            </li>

            <li>
              <time class="cbp_tmtime" datetime="2017-11-04T03:45"
                ><span>12h00</span></time
              >
              <div class="cbp_tmicon bg-blush">
                <i class="zmdi zmdi-pin"></i>
              </div>
              <div class="cbp_tmlabel">
                <h2>Antequera</h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import LeafletMapa from "../components/LafletMapa.vue";

export default {
  components: {
    LeafletMapa,
  },
};
</script>

<style scoped>
.transport-elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
}
.transport-items {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  border-radius: 30px;
}

body {
  margin-top: 20px;
  background: #fbfdff;
}
.cbp_tmtimeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cbp_tmtimeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #eee;
  left: 20%;
  margin-left: -6px;
}

.cbp_tmtimeline > li {
  position: relative;
}

.cbp_tmtimeline > li:first-child .cbp_tmtime span.large {
  color: #444;
  font-size: 17px !important;
  font-weight: 700;
}

.cbp_tmtimeline > li:first-child .cbp_tmicon {
  background: #fff;
  color: #666;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #444;
  font-size: 13px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: #f0f1f3;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #f0f1f3;
}

.cbp_tmtimeline > li .empty span {
  color: #777;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 23%;
  padding-right: 70px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 15px;
  color: #3d4c5a;
  font-weight: 700;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 14px;
  color: #444;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  background: #f0f1f3;
  padding: 1.2em;
  position: relative;
  border-radius: 5px;
}

.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #f0f1f3;
  border-width: 10px;
  top: 10px;
}

.cbp_tmtimeline > li .cbp_tmlabel blockquote {
  font-size: 16px;
}

.cbp_tmtimeline > li .cbp_tmlabel .map-checkin {
  border: 5px solid rgba(235, 235, 235, 0.2);
  -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
  -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
  box-shadow: 0px 0px 0px 1px #ebebeb;
  background: #fff !important;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 {
  margin: 0px;
  padding: 0 0 10px 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: normal;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 a {
  font-size: 15px;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 a:hover {
  text-decoration: none;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 span {
  font-size: 15px;
}

.cbp_tmtimeline > li .cbp_tmlabel p {
  color: #444;
}

.cbp_tmtimeline > li .cbp_tmicon {
  width: 40px;
  height: 40px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: #46a4da;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #f5f5f6;
  text-align: center;
  left: 20%;
  top: 0;
  margin: 0 0 0 -25px;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .cbp_tmtimeline > li .cbp_tmtime {
    padding-right: 60px;
  }
}

@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 12px;
  }
}

@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 30px 0;
    padding: 1em;
    font-weight: 400;
    font-size: 95%;
  }
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #f5f5f6;
    top: -20px;
  }
  .cbp_tmtimeline > li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -64px 5px 0 0px;
  }
  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #f5f5f6;
  }
}

.bg-green {
  background-color: #50d38a !important;
  color: #fff;
}

.bg-blush {
  background-color: #ff758e !important;
  color: #fff;
}

.bg-orange {
  background-color: #ffc323 !important;
  color: #fff;
}

.bg-info {
  background-color: #2ca8ff !important;
}
</style>
>
