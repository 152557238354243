<template>
  <header class="main-header">
    <nav class="navbar navbar-expand-lg navbar-light h3">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navmenu"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navmenu">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-auto">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#sec-nous">{{ $t("nosotros") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#sec-boda">{{ $t("la-boda") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#sec-logis">{{ $t("logistica") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#sec-contact">{{
                $t("contactotitle")
              }}</a>
            </li>
          </ul>
        </div>

        <div class="col-4">
          <select
            class="custom-select"
            v-model="lang"
            @change="handleChange($event)"
          >
            <option value="es">Espagnol</option>
            <option value="fr">Français</option>
            <option value="en">English</option>
          </select>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data: function () {
    const lang = localStorage.getItem("lang") || "fr";
    return {
      lang: lang,
    };
  },
  methods: {
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.custom-select {
  margin: 10px;
}
</style>
